<script lang="ts">
  import { fly } from "svelte/transition";

  export let value = 0;

  $: valueArr = [value];
</script>

<div class="inline-block p-1 text-2xl border rounded border-black overflow-hidden relative z-20">
  {#each valueArr as value (value)}
    <div class="z-10 absolute" in:fly|local={{ y: -77, duration: 250 }} out:fly|local={{ y: 77, duration: 250 }}>
      {value}
    </div>
  {/each}
  <div class="opacity-0">{value}</div>
</div>
