import { get, derived, type Readable, type Writable, writable } from "svelte/store";
import { apiKey, dataRefresh } from "../store";
import axios, { type AxiosResponse } from "axios";
import rateLimit from "axios-rate-limit";
import * as Sentry from "@sentry/svelte";
import { DateTime, Interval } from "luxon";

const baseUri = "https://api.rentman.net/";

export type MapStore = Writable<Record<string, Record<string, any>>>;
export type ListStore = Writable<Record<string, any>[]>;
export type MapStoreByUri = Readable<Record<string, Record<string, any>>>;

// Base store for all data
export let contacts: ListStore = writable([]);
export let crew: ListStore = writable([]);
export let equipment: ListStore = writable([]);
export let projectCrew: ListStore = writable([]);
export let projectFunction: ListStore = writable([]);
export let projects: ListStore = writable([]);
export let subProjects: ListStore = writable([]);
export let projectVehicles: ListStore = writable([]);
export let repairs: ListStore = writable([]);
export let timeRegistration: ListStore = writable([]);
export let vehicles: ListStore = writable([]);

// data by URI
export let contactsByUri: MapStoreByUri = derived(contacts, derivedByUri("contacts"));
export let crewByUri: MapStoreByUri = derived(crew, derivedByUri("crew"));
export let equipmentByUri: MapStoreByUri = derived(equipment, derivedByUri("equipment"));
export let projectFunctionByUri: MapStoreByUri = derived(projectFunction, derivedByUri("projectfunctions"));
export let projectsByUri: MapStoreByUri = derived(projects, derivedByUri("projects"));
export let vehiclesByUri: MapStoreByUri = derived(vehicles, derivedByUri("vehicles"));

export let apiUnavailableUntil: Writable<DateTime | null> = writable(null);

// Mutations
export let projectCrewToday = derived([projectCrew, crewByUri], ([$projectCrew, $crewByUri]) => {
  return $projectCrew.filter((projectCrew) => {
    if (!$crewByUri[projectCrew.crewmember] || $crewByUri[projectCrew.crewmember].displayname.indexOf("#") > -1) {
      return false;
    }

    let start = DateTime.fromISO(projectCrew.planperiod_start).startOf("day");
    let end = DateTime.fromISO(projectCrew.planperiod_end).endOf("day");
    return Interval.fromDateTimes(start, end).contains(DateTime.now());
  });
});

export let subProjectsToday = derived(subProjects, ($subProjects) => {
  return $subProjects
    .filter((subProject) => {
      let start = DateTime.fromISO(subProject.planperiod_start).startOf("day");
      let end = DateTime.fromISO(subProject.planperiod_end).endOf("day");
      return Interval.fromDateTimes(start, end).contains(DateTime.now());
    })
    .sort((a, b) => +DateTime.fromISO(a.planperiod_start) - +DateTime.fromISO(b.planperiod_start));
});

export let timePerCrewThisWeek = derived(
  timeRegistration,
  ($timeregistration, set) => {
    let crewTimeMap = {};

    let startThisWeek = DateTime.now().startOf("week");
    let endThisWeek = DateTime.now().endOf("week");
    for (let tr of $timeregistration) {
      let workStart = DateTime.fromISO(tr.start);
      if (+workStart >= +startThisWeek && +workStart <= +endThisWeek) {
        let workEnd = DateTime.fromISO(tr.end);
        let workedTime = workEnd.diff(workStart, "seconds").seconds - tr.break_duration;

        if (!crewTimeMap[tr.crewmember]) crewTimeMap[tr.crewmember] = 0;
        crewTimeMap[tr.crewmember] += workedTime;
      }
    }

    set(crewTimeMap);
  },
  {}
);

let refreshing = false;
function refresh() {
  if (refreshing) return;
  refreshing = true;

  getDataForListStore(contacts, "contacts", { fields: "id,displayname" })
    .then(() => {
      Promise.all([
        getDataForListStore(crew, "crew", { fields: "id,displayname,remark" }),
        getDataForListStore(equipment, "equipment", { fields: "id,displayname" }),
        getDataForListStore(projectCrew, "projectcrew", {
          fields: "id,displayname,crewmember,planperiod_start,planperiod_end,project_leader,function",
        }),
        getDataForListStore(projectFunction, "projectfunctions", { fields: "id,displayname,project" }),
        getDataForListStore(projects, "projects", { fields: "id,displayname,customer,number" }),
        getDataForListStore(
          subProjects,
          "subprojects",
          {
            fields: "id,displayname,project,name,creator,planperiod_start,planperiod_end,in_planning",
            status: "3,4,5",
          },
          (subProject) => !!subProject.in_planning
        ),
        getDataForListStore(projectVehicles, "projectvehicles", {
          fields: "id,displayname,planningperiod_start,vehicle,function",
          "planningperiod_start[gte]": DateTime.now().startOf("day").toISO(),
          "planningperiod_start[lte]": DateTime.now().endOf("day").toISO(),
        }),
        getDataForListStore(repairs, "repairs", { fields: "id,displayname,equipment,reporter,assignee,repairperiod_start" }),
        getDataForListStore(timeRegistration, "timeregistration", { fields: "id,displayname,start,end,break_duration,crewmember" }),
        getDataForListStore(vehicles, "vehicles", { fields: "id,displayname" }),
      ]).finally(() => {
        refreshing = false;
      });
    })
    .catch(() => {
      refreshing = false;
    });
}

let rentmanApi = rateLimit(axios.create(), {
  maxRequests: 10,
  perMilliseconds: 1000,
});

export function proxyGet(uri: string): Promise<AxiosResponse> {
  if (get(apiUnavailableUntil) !== null && +get(apiUnavailableUntil) > +DateTime.now()) {
    return new Promise((_, reject) => {
      reject();
    });
  }

  const proxyGetRequest = rentmanApi({
    url: `proxy/proxy.php?u=${uri}`,
    method: "GET",
    headers: {
      "X-Proxy-URL": `${baseUri}${uri}`,
      "Content-Type": "application/json",
      Authorization: `Bearer ${get(apiKey)}`,
    },
  });

  proxyGetRequest.then((response) => {
    Sentry.setContext(`proxyGet-${uri}`, {
      code: response.status,
      response: response.data,
    });

    if (response.data.message === "Limit Exceeded") {
      apiUnavailableUntil.set(DateTime.now().plus({ day: 1 }).startOf("day").plus({ hour: 6 }));
    }
  });

  return proxyGetRequest;
}

function getDataForListStore(
  store: ListStore,
  uri: string,
  params: Record<string, string> = {},
  filter: (item: Record<string, string>) => boolean = () => true
): Promise<void> {
  return new Promise(async (resolve, reject) => {
    if (!get(apiKey)) {
      reject();
      return;
    }

    let data = [];
    let itemCount = 0;
    let limit = 0;
    do {
      try {
        let response = await proxyGet(`${uri}?${new URLSearchParams(params).toString()}`);
        if (response.data.data) {
          data = data.concat(response.data.data);
          itemCount = response.data.itemCount;
          limit = response.data.limit;
          params.offset = response.data.offset + response.data.limit;
        } else {
          reject(new Error("No data in response"));
          return;
        }
      } catch (error) {
        reject(error);
        return;
      }
    } while (itemCount == limit);

    store.set(data.filter(filter));
    resolve();
  });
}

function derivedByUri(name: string) {
  return (store, set) => {
    let map = {};
    for (let item of store) {
      map[`/${name}/${item.id}`] = item;
    }
    set(map);
  };
}

// When api key changes
apiKey.subscribe(async (apiKey) => {
  if (!apiKey) return;
  contacts.set([]);
  crew.set([]);
  equipment.set([]);
  projectCrew.set([]);
  projectFunction.set([]);
  projects.set([]);
  subProjects.set([]);
  projectVehicles.set([]);
  repairs.set([]);
  timeRegistration.set([]);
  vehicles.set([]);
  refresh();
});

setInterval(refresh, get(dataRefresh));
refresh();
