import { readable, writable } from "svelte/store";
import { DateTime } from "luxon";

export let apiKey = writable(localStorage.getItem("apiKey") || "");
apiKey.subscribe((value) => localStorage.setItem("apiKey", value));

export let screenSpeed = writable(5000);
export let dataRefresh = writable(7200000);

export let now = readable(DateTime.now(), (set) => {
  const interval = setInterval(() => {
    set(DateTime.now());
  }, 1000);

  return () => {
    clearInterval(interval);
  };
});
