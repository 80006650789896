<script lang="ts">
  import DashCard from "../../../components/DashCard.svelte";
  import NestedStoreData from "../../../components/NestedStoreData.svelte";
  import ScrollingTable from "../../../components/ScrollingTable.svelte";
  import { contactsByUri, crewByUri, projectsByUri, subProjectsToday } from "../../../data/rentmanData.js";
</script>

<DashCard>
  <div class="card-title">Projecten</div>

  <ScrollingTable on:done headers={["Naam", "Klant", "Account manager"]}>
    {#each $subProjectsToday as subproject}
      <tr>
        <td>
          <NestedStoreData start={subproject.project} path={[[$projectsByUri, "displayname"]]} />
        </td>
        <td>
          <NestedStoreData
            start={subproject.project}
            path={[
              [$projectsByUri, "customer", "Geen gekoppelde klant"],
              [$contactsByUri, "displayname"],
            ]}
          />
        </td>
        <td>
          <NestedStoreData start={subproject.creator} path={[[$crewByUri, "displayname"]]} />
        </td>
      </tr>
    {/each}
  </ScrollingTable>
</DashCard>
