<script lang="ts">
  import { audioQueue, hasContext, MediaFile, nextAudioQueueItem, Speech } from "./audioQueue";

  let player: HTMLAudioElement;
  const noopAudio = "data:audio/wav;base64,UklGRigAAABXQVZFZm10IBIAAAABAAEARKwAAIhYAQACABAAAABkYXRhAgAAAAEA";
  let playing = false;

  $: {
    if ($hasContext && !playing && $audioQueue.length > 0) {
      playing = true;
      let item = nextAudioQueueItem();
      if (item instanceof Speech) {
        speak(item);
      } else if (item instanceof MediaFile) {
        play(item);
      }
    }
  }

  function speak(item: Speech) {
    if (typeof speechSynthesis == "undefined") {
      console.warn("Speech synthesis not supported");
      playing = false;
      return;
    }

    let utterance = new SpeechSynthesisUtterance(item.text);
    utterance.lang = "nl-NL";
    speechSynthesis.speak(utterance);
    utterance.addEventListener("end", () => {
      playing = false;
    });
  }

  function playerPlay(url: string, loop: boolean) {
    player.pause();
    player.loop = loop;
    player.src = url;
    player.currentTime = 0;
    player.play();
  }

  function play(item: MediaFile) {
    playerPlay(item.url, false);
    player.addEventListener(
      "ended",
      () => {
        playerPlay(noopAudio, true);
        playing = false;
      },
      { once: true }
    );
  }

  function getAudioContext() {
    player.play();
    $hasContext = true;
  }
</script>

{#if !$hasContext}
  <a class="btn btn-xs btn-primary" on:click={getAudioContext}>Zet spraak alerts aan</a>
{/if}
<audio hidden bind:this={player} src={noopAudio} loop />
