<script lang="ts">
  import { createEventDispatcher, onMount } from "svelte";

  let dispatch = createEventDispatcher();

  export let headers: string[];

  let tBody: HTMLElement;
  let scroller: HTMLElement;

  onMount(() => {
    let interval = setInterval(() => {
      let child = tBody.children[0];
      if (!child) {
        clearInterval(interval);
        dispatch("done");
        return;
      }

      let height = child.clientHeight;
      let before = scroller.scrollTop;
      scroller.scrollTo(0, scroller.scrollTop + height);

      setTimeout(() => {
        if (before === scroller.scrollTop) {
          clearInterval(interval);
          dispatch("done");
        }
      }, 1000);
    }, 2000);

    return () => clearInterval(interval);
  });
</script>

<div bind:this={scroller} class="overflow-x-auto w-full h-full max-h-full relative overflow-y-hidden scroll-smooth">
  <table class="table table-zebra w-full grow max-h-full">
    <thead class="sticky top-0 z-20">
      <tr>
        {#each headers as header}
          <th>{header}</th>
        {/each}
      </tr>
    </thead>
    <tbody bind:this={tBody}>
      <slot />
    </tbody>
  </table>
</div>
