<script lang="ts">
  import DashCard from "../../../components/DashCard.svelte";
  import { DateTime } from "luxon";
  import NestedStoreData from "../../../components/NestedStoreData.svelte";
  import ScrollingTable from "../../../components/ScrollingTable.svelte";
  import { contactsByUri, crewByUri, projectCrewToday, projectFunctionByUri, projectsByUri } from "../../../data/rentmanData.js";
  import { omit } from "lodash-es";
</script>

<DashCard>
  <div class="card-title">Planning</div>

  <ScrollingTable on:done headers={["Naam Technicus", "Start om", "Stopt om", "Functie", "Prj.leider", "Klant"]}>
    {#each $projectCrewToday as pc}
      <tr>
        <td>
          <NestedStoreData start={pc.crewmember} path={[[$crewByUri, "displayname"]]} />
        </td>
        <td>
          {#if DateTime.fromISO(pc.planperiod_start).hasSame(DateTime.now(), "day")}
            {DateTime.fromISO(pc.planperiod_start).toLocaleString(DateTime.TIME_24_SIMPLE)}
          {:else}
            {DateTime.fromISO(pc.planperiod_start).toLocaleString(omit(DateTime.DATETIME_MED, "year"))}
          {/if}
        </td>
        <td>
          {#if DateTime.fromISO(pc.planperiod_end).hasSame(DateTime.now(), "day")}
            {DateTime.fromISO(pc.planperiod_end).toLocaleString(DateTime.TIME_24_SIMPLE)}
          {:else}
            {DateTime.fromISO(pc.planperiod_end).toLocaleString(omit(DateTime.DATETIME_MED, "year"))}
          {/if}
        </td>
        <td>
          <NestedStoreData start={pc.function} path={[[$projectFunctionByUri, "displayname"]]} />
        </td>
        <td>
          {pc.project_leader ? "✅" : "❌"}
        </td>
        <td>
          <NestedStoreData
            start={pc.function}
            path={[
              [$projectFunctionByUri, "project"],
              [$projectsByUri, "customer", "Geen gekoppelde klant"],
              [$contactsByUri, "displayname"],
            ]}
          />
        </td>
      </tr>
    {/each}
  </ScrollingTable>
</DashCard>
