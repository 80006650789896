<script lang="ts">
  import DashCard from "../../../components/DashCard.svelte";
  import NestedStoreData from "../../../components/NestedStoreData.svelte";
  import ScrollingTable from "../../../components/ScrollingTable.svelte";
  import { crewByUri, timePerCrewThisWeek } from "../../../data/rentmanData.js";
  import { Duration } from "luxon";
</script>

<DashCard>
  <div class="card-title">Tijd deze week</div>

  <ScrollingTable on:done headers={["Naam Technicus", "Geschreven tijd"]}>
    {#each Object.entries($timePerCrewThisWeek) as [crewMember, time]}
      <tr>
        <td>
          <NestedStoreData start={crewMember} path={[[$crewByUri, "displayname"]]} />
        </td>
        <td>
          {Duration.fromObject({ seconds: time }).rescale().toHuman()}
        </td>
      </tr>
    {/each}
  </ScrollingTable>
</DashCard>
