import { get, type Writable, writable } from "svelte/store";

export let hasContext = writable(false);

type AudioItem = Speech | MediaFile;
export let audioQueue: Writable<AudioItem[]> = writable([]);

export function addToAudioQueue(item: AudioItem) {
  if (!get(hasContext)) {
    console.warn("No context to play audio in");
    return;
  }

  audioQueue.update((queue) => {
    queue.push(item);
    return queue;
  });
}

export function nextAudioQueueItem(): AudioItem {
  let item;
  audioQueue.update((queue) => {
    item = queue.shift();
    return queue;
  });
  return item;
}

export class Speech {
  constructor(public text: string) {}
}

export class MediaFile {
  constructor(public url: string) {}
}
