import App from "./App.svelte";
import "./tailwind.css";

import { Settings } from "luxon";
Settings.defaultLocale = "nl";

import * as Sentry from "@sentry/svelte";
import { BrowserTracing } from "@sentry/tracing";

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: "https://6bb4043fc8fd455e8e93b47f47c9c86b@o393302.ingest.sentry.io/4504101110218752",
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    release: `rentman-dashboard@v${__APP_VERSION__}`,
    environment: "production",
  });
}

let target = document.getElementById("app");

const app = new App({
  target,
  props: {
    refresh: Number(target.getAttribute("x-refresh")) || 60000,
    speed: Number(target.getAttribute("x-speed")) || 5000,
  },
});

export default app;
