<script lang="ts">
  import Projects from "./components/Projects.svelte";
  import Repairs from "./components/Repairs.svelte";
  import { onMount } from "svelte";
  import { screenSpeed } from "../../store";
  import Planning from "./components/Planning.svelte";
  import TimeThisWeek from "./components/TimeThisWeek.svelte";

  let page = 0;
  let pageIsDone = false;
  let timeIsDone = false;
  let timer: number = null;

  function setTimer() {
    timer = setTimeout(() => {
      timeIsDone = true;
    }, $screenSpeed);
  }

  $: {
    if (pageIsDone && timeIsDone) {
      pageIsDone = false;
      timeIsDone = false;
      page = (page + 1) % 4;
      setTimer();
    }
  }

  onMount(() => {
    setTimer();

    return () => {
      clearTimeout(timer);
    };
  });
</script>

<div class="full flex-center p-5">
  <div class="full flex-center relative">
    {#if page === 0}
      <Projects on:done={() => (pageIsDone = true)} />
    {:else if page === 1}
      <Repairs on:done={() => (pageIsDone = true)} />
    {:else if page === 2}
      <Planning on:done={() => (pageIsDone = true)} />
    {:else if page === 3}
      <TimeThisWeek on:done={() => (pageIsDone = true)} />
    {/if}
  </div>
</div>
