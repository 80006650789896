<script lang="ts">
  import { apiKey, dataRefresh, now, screenSpeed } from "./store.js";
  import SetApiKey from "./pages/SetApiKey.svelte";
  import Dashboard from "./pages/dashboard/Dashboard.svelte";
  import Router from "svelte-spa-router";
  import Upcoming from "./pages/upcoming/Upcoming.svelte";
  import { onMount } from "svelte";
  import { apiUnavailableUntil } from "./data/rentmanData.js";

  export let refresh: number;
  export let speed: number;

  const routes = {
    "/": Dashboard,
    "/upcoming": Upcoming,
    "*": Dashboard,
  };

  onMount(() => {
    if (speed) $screenSpeed = speed;
    if (refresh) $dataRefresh = refresh;
  });
</script>

<div id="logo" />

<main class="w-screen h-screen flex flex-col overflow-hidden z-10" id="main">
  {#if $apiKey}
    <Router {routes} />
  {:else}
    <SetApiKey />
  {/if}

  {#if $apiUnavailableUntil !== null && +$apiUnavailableUntil > +$now}
    <div class="flex absolute bottom-2 left-2">
      <div class="alert alert-warning shadow-lg">
        <div>
          <div>
            <h3 class="font-bold text-sm">Rentman API limiet bereikt.</h3>
            <div class="text-xs">
              Refresh gepauzeerd voor {$apiUnavailableUntil
                .diff($now.startOf("minute"), ["hours", "minutes"])
                .normalize()
                .toHuman({ listStyle: "short", unitDisplay: "short" })}.
            </div>
          </div>
        </div>
      </div>
    </div>
  {/if}
</main>
