<script lang="ts">
  import type { MapStore } from "../data/rentmanData";

  export let start: string = "";
  export let path: [MapStore, string, string | null][] = [];
  export let transpose = (value: string) => value;

  let data: string | null = null;
  let broken: boolean;
  $: {
    let currentValue = start;
    broken = false;
    for (const [store, key, ifNot] of path) {
      let currentStoreObject = store[currentValue];
      if (!store[currentValue]) {
        data = ifNot;
        broken = true;
        break;
      }
      currentValue = currentStoreObject[key];
    }

    if (!broken) {
      data = currentValue;
    }
  }
</script>

{#if broken && data}
  <i>{data}</i>
{:else if data}
  {transpose(data)}
{:else}
  <progress class="progress w-20" />
{/if}
