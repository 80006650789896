<script lang="ts">
  import { apiKey } from "../store";

  let apiKeyInput: string = "";
</script>

<div class="flex-center h-full">
  <div class="card w-96 bg-base-100 shadow-xl">
    <div class="card-body">
      <h2 class="card-title">API Sleutel</h2>
      <p>
        Plak hieronder de API sleutel (JWT / Bearer token) van Rentman. Deze
        wordt opgeslagen in je browser.
      </p>
      <input
        bind:value={apiKeyInput}
        type="text"
        placeholder="API Sleutel"
        class="input input-bordered w-full"
      />
      <div class="card-actions">
        <button class="btn btn-primary" on:click={() => ($apiKey = apiKeyInput)}
          >Opslaan</button
        >
      </div>
    </div>
  </div>
</div>
