<script lang="ts">
  import DashCard from "../../../components/DashCard.svelte";
  import { DateTime } from "luxon";
  import NestedStoreData from "../../../components/NestedStoreData.svelte";
  import ScrollingTable from "../../../components/ScrollingTable.svelte";
  import { crewByUri, equipmentByUri, repairs } from "../../../data/rentmanData.js";
</script>

<DashCard>
  <div class="card-title">Reparaties en/of niet inzetbaar</div>

  <ScrollingTable on:done headers={["Materiaal", "Aangemeld door", "Toegewezen aan", "Start"]}>
    {#each $repairs as repair}
      <tr>
        <td>
          {#if repair.equipment}
            <NestedStoreData start={repair.equipment} path={[[$equipmentByUri, "displayname"]]} />
          {:else}
            <i>Geen</i>
          {/if}
        </td>
        <td>
          {#if repair.reporter}
            <NestedStoreData start={repair.reporter} path={[[$crewByUri, "displayname"]]} />
          {:else}
            <i>Onbekend</i>
          {/if}
        </td>
        <td>
          {#if repair.assignee}
            <NestedStoreData start={repair.assignee} path={[[$crewByUri, "displayname"]]} />
          {:else}
            <i>Niemand toegewezen</i>
          {/if}
        </td>
        <td>
          {DateTime.fromISO(repair.repairperiod_start).toLocaleString(DateTime.DATE_MED)}
        </td>
      </tr>
    {/each}
  </ScrollingTable>
</DashCard>
