<script lang="ts">
  import DashCard from "../../components/DashCard.svelte";
  import { DateTime } from "luxon";
  import { now } from "../../store.js";
  import NestedStoreData from "../../components/NestedStoreData.svelte";
  import FlipCard from "./components/FlipCard.svelte";
  import AudioContext from "../../components/AudioContext.svelte";
  import { addToAudioQueue, MediaFile, Speech } from "../../components/audioQueue";
  import { contactsByUri, projectFunctionByUri, projectsByUri, projectVehicles, vehiclesByUri } from "../../data/rentmanData";

  $: upcomingProjectVehicles = $projectVehicles
    .filter((pv) => DateTime.fromISO(pv.planningperiod_start) >= $now.minus({ minute: 5 }))
    .map((pv) => ({
      ...pv,
      timeDiff: DateTime.fromISO(pv.planningperiod_start)
        .startOf("second")
        .diff($now.startOf("second"), ["hours", "minutes", "seconds"])
        .normalize(),
    }));

  $: {
    for (const pv of upcomingProjectVehicles) {
      if (+DateTime.fromISO(pv.planningperiod_start).startOf("second") == +$now.startOf("second")) {
        const alertVehicle = $vehiclesByUri[pv.vehicle];
        const alertContact = $contactsByUri[$projectsByUri[$projectFunctionByUri[pv.function]?.project]?.customer];

        if (!alertVehicle || !alertContact) continue;

        const play = () => {
          addToAudioQueue(new MediaFile("/assets/alert.mp3"));
          addToAudioQueue(
            new Speech(
              `Attentie. ${alertVehicle.displayname} voor klant ${alertContact.displayname} met eindbestemming ${alertContact.visit_city} dient nu te vertrekken.`
            )
          );
        };

        play();
        setTimeout(play, 30 * 1000);
      }
    }
  }
</script>

<div class="full flex-center p-5">
  <div class="full flex-center relative">
    <DashCard>
      <div class="card-title">Vertrekplanning <AudioContext /></div>

      <div class="overflow-x-auto">
        <table class="table table-zebra w-full">
          <thead>
            <tr>
              <th>Voertuig</th>
              <th>Vertrekken over</th>
              <th>Prj#</th>
              <th>Klant</th>
            </tr>
          </thead>
          <tbody>
            {#each upcomingProjectVehicles as pv}
              <tr>
                <td>
                  <NestedStoreData start={pv.vehicle} path={[[$vehiclesByUri, "displayname"]]} />
                </td>
                <td class="flex items-center">
                  {#if DateTime.fromISO(pv.planningperiod_start) > $now.startOf("second")}
                    {#each ("0" + pv.timeDiff.hours).slice(-2).split("") as value}
                      <FlipCard {value} />
                    {/each}
                    <div class="p-1">:</div>
                    {#each ("0" + pv.timeDiff.minutes).slice(-2).split("") as value}
                      <FlipCard {value} />
                    {/each}
                    <div class="p-1">:</div>
                    {#each ("0" + pv.timeDiff.seconds).slice(-2).split("") as value}
                      <FlipCard {value} />
                    {/each}
                  {:else}
                    <b>Tijd om te vertrekken</b>
                  {/if}
                </td>
                <td>
                  <NestedStoreData
                    start={pv.function}
                    path={[
                      [$projectFunctionByUri, "project"],
                      [$projectsByUri, "number"],
                    ]}
                  />
                </td>
                <td>
                  <NestedStoreData
                    start={pv.function}
                    path={[
                      [$projectFunctionByUri, "project"],
                      [$projectsByUri, "customer", "Geen gekoppelde kant"],
                      [$contactsByUri, "displayname"],
                    ]}
                    transpose={(value) => value.replace("/projects/", "")}
                  />
                </td>
              </tr>
            {/each}
          </tbody>
        </table>
      </div>
    </DashCard>
  </div>
</div>
